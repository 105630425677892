// Generated by Framer (2b3524c)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useLocaleInfo, useVariantState, withCSS, withFX } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
const MotionDivWithFX = withFX(motion.div);

const cycleOrder = ["QcVmQrFX6", "XDXtGp41U"];

const serializationHash = "framer-S47ts"

const variantClassNames = {QcVmQrFX6: "framer-v-1bph2aj", XDXtGp41U: "framer-v-okqvqv"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 50, delay: 0.5, mass: 2.8, stiffness: 300, type: "spring"}};

const transformTemplate = (_, t) => `perspective(1200px) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {"Variant 1": "QcVmQrFX6", "Variant 2": "XDXtGp41U"}

const getProps = ({height, id, scrollSection, width, ...props}) => { return {...props, IF9SIsmRd: scrollSection ?? props.IF9SIsmRd, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "QcVmQrFX6"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;scrollSection?: React.MutableRefObject<HTMLElement>; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, IF9SIsmRd, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "QcVmQrFX6", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Transition value={transition}><MotionDivWithFX {...restProps} __framer__spring={{damping: 50, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 2.8, stiffness: 300, type: "spring"}} __framer__styleTransformEffectEnabled __framer__transformTargets={[{target: {opacity: 1, rotate: 0, rotateX: 0, rotateY: 0, scale: 0.01, x: 0, y: 0}}, {ref: IF9SIsmRd, target: {opacity: 1, rotate: 0, rotateX: 0, rotateY: 0, scale: 1, x: 0, y: 0}}]} __framer__transformTrigger={"onScrollTarget"} __framer__transformViewportThreshold={0.5} __perspectiveFX={false} __smartComponentFX __targetOpacity={1} animate={variants} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1bph2aj", className, classNames)} data-framer-name={"Variant 1"} initial={variant} layoutDependency={layoutDependency} layoutId={"QcVmQrFX6"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{backgroundColor: "var(--token-ba2208b7-789f-4c96-a801-3cea7d5fa689, rgb(24, 39, 64))", borderBottomLeftRadius: 1064, borderBottomRightRadius: 1064, borderTopLeftRadius: 1064, borderTopRightRadius: 1064, ...style}} transformTemplate={transformTemplate} variants={{XDXtGp41U: {borderBottomLeftRadius: 0, borderBottomRightRadius: 0, borderTopLeftRadius: 0, borderTopRightRadius: 0}}} {...addPropertyOverrides({XDXtGp41U: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}/></Transition>
</LayoutGroup>)

});

const css = [".framer-S47ts[data-border=\"true\"]::after, .framer-S47ts [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-S47ts.framer-934gkb, .framer-S47ts .framer-934gkb { display: block; }", ".framer-S47ts.framer-1bph2aj { height: 2000px; overflow: hidden; position: relative; width: 2000px; will-change: var(--framer-will-change-override, transform); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 2000
 * @framerIntrinsicWidth 2000
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"XDXtGp41U":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"IF9SIsmRd":"scrollSection"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const FramerYpzjsw9ig: React.ComponentType<Props> = withCSS(Component, css, "framer-S47ts") as typeof Component;
export default FramerYpzjsw9ig;

FramerYpzjsw9ig.displayName = "background animation";

FramerYpzjsw9ig.defaultProps = {height: 2000, width: 2000};

addPropertyControls(FramerYpzjsw9ig, {variant: {options: ["QcVmQrFX6", "XDXtGp41U"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}, IF9SIsmRd: {title: "Scroll Section", type: ControlType.ScrollSectionRef}} as any)

addFonts(FramerYpzjsw9ig, [])